@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;500;600;700;800;900&display=swap');


*{

  box-sizing: border-box;
  margin: 0;
  padding:0;
  font-family: 'Outfit', sans-serif;


}

body{
  background:black;
}

h1,h4,p,a{
  color: #fff;
  text-decoration: none;
}

ul{
  list-style-type: none;
}

.btn{

  padding: 12px 32px;
  font-size: 1rem;
  text-transform:uppercase ;
  /*background: rgb(248,217,15); */ 
 background: dodgerblue;  
  color: #222;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;


}

.btn-light{
  padding: 12px 32px;
  font-size: 1rem;
  background:transparent;
  border: 1px solid #fff;
  color: white;
  
}

.btn:hover{
  background:transparent;
  color: #fff;
  transition:0.3s;
}

.btn-light:hover{
  background-color: rgba(255,255,255,0.2); 
  color: #fff;
  transition:0.3s;
}