.footer {
    width: 100%;
    padding: 6rem 0;
    background-color: rgba(19, 19, 19, 0.8);
    color: #fff;
    text-align: center;
  }
  
  .footer-container {
    max-width: 1140px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .footer-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
  }
  
  .phone,
  .email {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .phone h4,
  .email h4 {
    margin-left: 1rem;
  }
  
  .footer-right {
    flex: 1;
    text-align: center;
    margin-top: 1rem;
  }
  
  .social {
    margin-top: 1rem;
  }
  
  .social a {
    margin-right: 1rem;
    color: #fff;
    font-size: 1.5rem;
  }
  
  .footer-rights {
    flex-basis: 100%;
    margin-top: 2rem;
    font-weight: 300;
  }
  
  .footer-rights p {
    font-size: 1rem;
  }
  
  @media screen and (max-width: 640px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-left,
    .footer-right {
      text-align: center;
    }
  
    .footer-rights {
      text-align: center;
      margin-top: 1rem;
    }
  }
  