@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&family=Source+Code+Pro&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');






.about{
    width: 100%;
    margin: 3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
}

.about .left{
    text-align: center;
    margin: auto;
    padding: 1rem;
    max-width: 350px;


}

.about .left p{
    margin: 1.2rem 0;
    font-family: 'Alegreya Sans';

}



.about .right{
    max-width: 700px;
}

.right .img-container{
    display: grid;
    grid-template-columns: repeat(12,1fr);
    position: relative;
    align-items: center;
    text-align: center;
}


.right .img{
    max-width: 90%;
    border: 1px solid #333;
}

.right .top{
    grid-column: 1 / span 8;
    grid-row: 1;
    padding-top: 20%;
    z-index: 6;
}

.right .bottom{
    grid-column: 4 / -1;
    grid-row: 1;
    z-index: 5;
}



  
  
  